@use "sass:map";

$theme-colors: (
  "primary": #4090ba,
  "light": #f8f9fa,
  "dark": #343a40,
);

:export {
  primary: map-get($theme-colors, "primary");
}
