$primary: map-get($theme-colors, "primary");
$light: map-get($theme-colors, "light");
$dark: map-get($theme-colors, "dark");

.overlay-primary,
.overlay-light,
.overlay-dark {
  padding: 20rem 0rem;
}

.overlay-primary {
  background-color: rgba($primary, 0.6);
  color: $light;
}

.overlay-light {
  background-color: rgba($light, 0.6);
  color: $dark;
}

.overlay-dark {
  background-color: rgba(#000000, 0.6);
  color: $light;
}
