@media (max-width: 400px) {
  .contact-item {
    font-size: 1.25rem;
  }
}

@media (max-width: 500px) {
  .header-logo-image {
    width: 12rem;
    max-width: 20rem;
    background: white;
    padding: 1rem;
    border-radius: 15px;
  }
}
