#about,
#services,
#contact {
  padding-top: 100px;
}

h4 {
  line-height: 1.5 !important;
}

.header-logo-image {
  width: 100%;
  max-width: 20rem;
  background: white;
  padding: 1rem;
  border-radius: 25px;
}

.service-item {
  height: 100%;
}

@import "./variables.scss";
@import "./backgrounds.scss";
@import "./responsive.scss";
@import "~bootstrap/scss/bootstrap";
